<template>
  <div class="update-password">
    <div class="content">
      <div class="mobile-test">
        <div class="test-tip">
          <svg-icon icon-class="warning" class="svg" />
          验证码已发送到您的手机，10分钟内输入有效，请勿泄露。
        </div>
        <el-form
          ref="applyBillForm"
          :model="form"
          :rules="formRules"
          label-width="180px"
          label-position="right"
        >
          <el-form-item label="手机号码" style="margin-bottom: 20px;">
            {{ phone }}
          </el-form-item>
          <div v-if="isShowCode" style="display: flex;margin-bottom: 20px;">
            <el-form-item style="margin-bottom: 0;" label="验证码" prop="code">
              <el-input v-model="form.code" placeholder="请输入验证码" style="width: 150px;margin-right: 12px;" />
            </el-form-item>
            <div style="width: 138px;" @click="getCode">
              <img v-if="image" class="image" :src="image" style="cursor: pointer;" alt="点击刷新">
              <el-button v-else type="text">点击刷新</el-button>
            </div>
          </div>
          <el-form-item label="手机验证码" prop="mobileCode" style="margin-bottom: 20px;">
            <div class="phone-code-box">
              <el-input v-model="form.mobileCode" style="width: 300px;" placeholder="请输入手机验证码" @focus="mobileCodeFocus" @blur="mobileCodeBlur" />
              <el-button class="send" :loading="sendLoading" :disabled="num !== ''" @click="send">
                重发{{ num ? '(' + num + ')' : '' }}
              </el-button>
            </div>
            <div style="color: #2a9c3d;font-size: 12px;line-height: 1;margin-top: 6px;">
              请输入您的手机收到的验证码
            </div>
          </el-form-item>
          <el-form-item label="新密码" prop="password" style="margin-bottom: 20px;">
            <el-input v-model="form.password" :type="passwordType" autocomplete="new-password" style="width: 300px;" placeholder="请输入新密码" @focus="passwordFocus" @blur="passwordBlur">
              <template #suffix>
                <svg-icon style="cursor: pointer;margin-right: 8px;" class="svg" :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" @click="showPwd" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="确认新密码" prop="checkPassword" style="margin-bottom: 20px;">
            <el-input v-model="form.checkPassword" :type="rePasswordType" autocomplete="new-password" style="width: 300px;" placeholder="请再次输入新密码" @focus="checkPasswordFocus" @blur="checkPasswordBlur">
              <template #suffix>
                <svg-icon style="cursor: pointer;margin-right: 8px;" class="svg" :icon-class="rePasswordType === 'password' ? 'eye' : 'eye-open'" @click="reShowPwd" />
              </template>
            </el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: center;">
          <el-button style="width: 80px;" type="primary" :loading="submitLoading" @click="submit">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { phoneFindPassword, captcha } from '@/api/user'
import { getFindPasswordSms } from '@/api/sms'
import { ElMessage } from 'element-plus'
import validate from '@/utils/baseValidate.js'
export default {
  name: 'UpdatePassword',
  data() {
    const validateCheckPassword = (rule, value, callback) => {
      if (this.status || this.comstatus.checkPassword) {
        if (value.trim() !== this.form.password.trim()) {
          callback(new Error('再次输入密码不一致'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (this.status || this.comstatus.mobileCode) {
        if (!value.trim()) {
          callback(new Error('验证码不能为空'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      submitLoading: false,
      sendLoading: false,
      passwordType: 'password',
      rePasswordType: 'password',
      phone: '',
      num: '',
      isShowCode: false,
      keys: '',
      image: '',
      form: {
        code: '',
        mobileCode: '',
        password: '',
        checkPassword: ''
      },
      status: false,
      comstatus: {
        mobileCode: false,
        password: false,
        checkPassword: false
      },
      formRules: {
        mobileCode: [
          { required: true, validator: validateCode, trigger: 'blur' }
        ],
        password: [
          { required: true, validator: validate.password, trigger: 'blur' }
        ],
        checkPassword: [
          { required: true, validator: validateCheckPassword, trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.timing()
    this.phone = this.$route.query.phone
    this.getCode()
  },
  methods: {
    // 获取验证码
    getCode() {
      captcha().then((res) => {
        this.image = res.data.image
        this.keys = res.data.key
      })
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = 'text'
      } else {
        this.passwordType = 'password'
      }
      // this.$nextTick(() => {
      //   this.$refs.passwordRef.focus()
      // })
    },
    reShowPwd() {
      if (this.rePasswordType === 'password') {
        this.rePasswordType = 'text'
      } else {
        this.rePasswordType = 'password'
      }
      // this.$nextTick(() => {
      //   this.$refs.rePasswordRef.focus()
      // })
    },
    // 用户光标聚焦
    mobileCodeFocus() {
      this.comstatus.mobileCode = false
      this.$refs.applyBillForm.clearValidate('mobileCode')
    },
    // 用户光标离开
    mobileCodeBlur() {
      this.status = false
      if (this.form.mobileCode !== '') {
        this.comstatus.mobileCode = true
      }
    },
    // 用户光标聚焦
    passwordFocus() {
      this.comstatus.password = false
      this.$refs.applyBillForm.clearValidate('password')
    },
    // 用户光标离开
    passwordBlur() {
      this.status = false
      if (this.form.password !== '') {
        this.comstatus.password = true
      }
    },
    // 用户光标聚焦
    checkPasswordFocus() {
      this.comstatus.checkPassword = false
      this.$refs.applyBillForm.clearValidate('checkPassword')
    },
    // 用户光标离开
    checkPasswordBlur() {
      this.status = false
      if (this.form.checkPassword !== '') {
        this.comstatus.checkPassword = true
      }
    },
    // 计时器
    timing() {
      this.num = 60
      const time = setInterval(() => {
        this.num--
        if (this.num === 0) {
          this.num = ''
          clearInterval(time)
        }
      }, 1000)
    },
    send() {
      this.isShowCode = true
      if (this.form.code === '') {
        ElMessage.error('请输入图片验证码！')
        return
      }
      const data = {
        account: this.$route.query.account,
        captchaKey: this.keys,
        code: this.form.code,
        phone: this.$route.query.phone
      }
      this.sendLoading = true
      getFindPasswordSms(data).then(res => {
        if (res.meta.status === 200) {
          this.timing()
          this.isShowCode = false
          this.form.code = ''
          setTimeout(() => {
            this.sendLoading = false
          }, 350)
        } else {
          ElMessage.error(res.meta.msg)
          this.sendLoading = false
          this.getCode()
        }
      }).catch(() => {
        this.getCode()
        this.sendLoading = false
      })
    },
    submit() {
      this.status = true
      this.$refs.applyBillForm.validate(valid => {
        if (valid) {
          this.submitLoading = true
          const data = {
            passwd: this.form.password.trim(),
            smsCode: this.form.mobileCode.trim()
          }
          phoneFindPassword(data, this.$route.query.account).then(res => {
            if (res.meta.status === 201) {
              ElMessage.success('找回成功')
              this.$router.push('/login')
              setTimeout(() => {
                this.submitLoading = false
              }, 350)
            } else {
              ElMessage.error(res.meta.msg)
              this.getCode()
              setTimeout(() => {
                this.submitLoading = false
              }, 350)
            }
            // eslint-disable-next-line handle-callback-err
          }).catch(error => {
            setTimeout(() => {
              this.submitLoading = false
              this.sendLoading = false
              this.getCode()
            }, 350)
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .update-password {
    height: 100%;
    width: 100%;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    .mobile-test {
      width: 650px;
      font-size: 12px;
      .test-tip {
        margin: 20px;
        height: 40px;
        line-height: 40px;
        background: #f9f1da;
        color: #202020;
        .svg {
          margin: 0 10px;
        }
      }
      .send.el-button {
        display: inline-block;
        width: 88px;
        height: 40px;
        /*line-height: 40px;*/
        background: #f2f2f2;
        color: #737d85;
        /*font-size: 12px;*/
        /*text-align: center;*/
        /*cursor: pointer;*/
        margin-left: 12px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .content {
      font-size: 12px;
      color: #313a46;
      padding-top: 72px;
      ::v-deep .el-input__suffix-inner{
            // line-height: 32px;
            padding-top: 10px;
          }
    }
  }
</style>
